import { NavigationScopeMap, SubjectKeysMap } from "../types/subjects";

export const subjectLabels = [
  "access",
  "app",
  "assist",
  "butler",
  "gates",
  "general",
  "inspire",
  "library_app",
  "library_assist",
  "people_counter",
  "poster",
];

export const subjectKeysMap: SubjectKeysMap = {
  ACCESS: "access",
  APP: "app",
  ASSIST: "assist",
  BUTLER: "butler",
  GATES: "gates",
  GENERAL: "general",
  INSPIRE: "inspire",
  LIBRARY_APP: "library_app",
  LIBRARY_ASSIST: "library_assist",
  PEOPLE_COUNTER: "people_counter",
  POSTER: "poster",
};

export const navigationSections = [
  "configuration",
  "content",
  "customer_settings",
  "operation",
  "users",
  "translation_management",
];

export const navigationScopeMap: NavigationScopeMap = {
  ACCESS: "access",
  ACCESS_TRANSLATIONS: "access_translations",
  ANNOUNCEMENTS: "announcements",
  AUDIO_CONTROL: "audio_control",
  BLOCK_LIST: "block_list",
  BRANCH_CONFIGURATION: "branch_configuration",
  BUTLER: "butler",
  BUTLER_TRANSLATIONS: "butler_translations",
  CHECKINS_AND_CHECKOUTS: "checkins_and_checkouts",
  COMMON_CONFIG: "common_config",
  CUSTOMER: "customer",
  CUSTOMER_GROUPS: "customer_groups",
  EVENT_STATES: "event_states",
  EXTERNAL_SYSTEMS: "external_systems",
  IDENTITY: "identity",
  IP_ADDRESSES: "ip_addresses",
  LIBRARY_APP: "library_app",
  LIBRARY_APP_TRANSLATIONS: "library_app_translations",
  LIBRARY_SYSTEM: "library_system",
  LOG: "log",
  META_UNIT_STATUS: "meta_unit_status",
  NEWS_AND_EVENTS: "news_and_events",
  OPENING_HOURS: "openingHours",
  OVERVIEW: "overview",
  SCHEDULING: "scheduling",
  STATISTICS: "statistics",
  SURVEYS: "surveys",
  TRANSLATIONS: "translations",
  UNITS: "units",
  UNIT_STATUS: "unit_status",
  USER_ADMINISTRATION: "user_administration",
};
