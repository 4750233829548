import {
  ProductKeysMap,
  ProductKeysMapLowerCase,
  ProductLabelsMap,
} from "../types/products";

export const DDBCMS = "ddbcms";

export const productLabels = [
  "access",
  "app",
  "assist",
  "butler",
  "ereolen",
  "ereolengo",
  "gates",
  "inspire",
  "library_app",
  "library_assist",
  "people_counter",
  "poster",
];

export const productKeysMap: ProductKeysMap = {
  APP: "APP",
  ASSIST: "ASSIST",
  BUTLER: "BUTLER",
  DOOR_ACCESS: "DOORACCESS",
  EREOLEN: "EREOLEN",
  EREOLENGO: "EREOLENGO",
  GATES: "GATES",
  INSPIRE: "INSPIRE",
  LIBRARY_APP: "LIBRARY_APP",
  LIBRARY_ASSIST: "LIBRARY_ASSIST",
  PEOPLE_COUNTER: "PEOPLE_COUNTER",
  POSTER: "POSTER",
};

export const productKeysMapLowerCase: ProductKeysMapLowerCase = {
  APP: "app",
  ASSIST: "assist",
  BUTLER: "butler",
  DOOR_ACCESS: "dooraccess",
  EREOLEN: "ereolen",
  EREOLENGO: "ereolengo",
  GATES: "gates",
  INSPIRE: "inspire",
  LIBRARY_APP: "libraryapp",
  LIBRARY_ASSIST: "library_assist",
  PEOPLE_COUNTER: "people_counter",
  POSTER: "poster",
};

export const productLabelsMap: ProductLabelsMap = {
  APP: "app",
  ASSIST: "assist",
  BUTLER: "butler",
  DOORACCESS: "access",
  EREOLEN: "ereolen",
  EREOLENGO: "ereolengo",
  GATES: "gates",
  INSPIRE: "inspire",
  LIBRARY_APP: "library_app",
  LIBRARY_ASSIST: "library_assist",
  PEOPLE_COUNTER: "people_counter",
  POSTER: "poster",
};
